import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from '../../../models/user.models';
import { Logs } from '../../../models/logs.models';
import { NzModalService } from "ng-zorro-antd/modal";
import { Observable } from 'rxjs';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  logs: Logs;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.authenticate(this.validateForm.value);
  }

  authenticate(user: User) {
    this.authService.login(user).subscribe(resp => { 
      this.router.navigateByUrl("/home");
    }, err => this.error(err.message));
  }

  error(message): void {
    this.modalService.error({
      nzTitle: "Error",
      nzContent: "Credenciales incorrectas, favor de intentar de nuevo o contactar al administrador"
    });
  }

}
