import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.models';
import { map, delay } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Logs } from '../models/logs.models';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private url = "https://identitytoolkit.googleapis.com/v1/accounts";
  private apikey = "AIzaSyCw54-4m0vfeKq6ct6v0kY2kJb8fOGurcg";
  private apiUrl = "https://tablero-iamsa.firebaseio.com";


  userToken: string;
  localId: string;

  constructor(private http: HttpClient) {
    this.getToken();
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("idToken");
    localStorage.removeItem("localId");
    localStorage.removeItem("expire");
  }

  login(user: User) {
    const authData = {
      ...user,
      returnSecureToken: true
    };

    let apiCall = this.http
      .post(`${this.url}:signInWithPassword?key=${this.apikey}`, authData)
      .pipe(
        map(resp => {
          this.setToken(resp["idToken"]);
          this.setLocalId(resp["localId"]);
          return resp;
        })
      );
    apiCall.subscribe((resp:any) => {
      resp.timestamp = new Date().getTime();
      resp.log_date = new Date();
      this.createLog(resp);
      this.createUserLog(resp);
    });
    return apiCall;
  }

  newUser(user: User) {
    const authData = {
      ...user,
      returnSecureToken: true
    };
    return this.http
      .post(`${this.url}:signUp?key=${this.apikey}`, authData)
      .pipe(
        map(resp => {
          this.setToken(resp["idToken"]);
          return resp;
        })
      );
  }

  private setToken(idToken: string) {
    this.userToken = idToken;
    localStorage.setItem("token", idToken);

    let today = new Date();
    today.setSeconds(3600);
    localStorage.setItem("expire", today.getTime().toString());
  }

  private setLocalId(localId: string) {
    this.localId = localId;
    localStorage.setItem("localId", localId);
  }

  private getToken() {
    if (localStorage.getItem("token")) {
      this.userToken = localStorage.getItem("token");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  private getLocalId() {
    if (localStorage.getItem("localId")) {
      this.localId = localStorage.getItem("localId");
    } else {
      this.localId = "";
    }
    return this.localId;
  }

  isAuthenticated(): boolean {
    return this.userToken.length > 2;
  }

  createUserLog(log) {
    return this.http
      .post(`${this.apiUrl}/users/${this.getLocalId()}/logs.json`, log)
      .subscribe(resp => console.log(resp), err => console.log(err));
  }

  createLog(log) {
    return this.http
      .post(`${this.apiUrl}/logs.json`, log)
      .subscribe(resp => console.log(resp), err => console.log(err));
  }

  getLogs() {
    return this.http.get(`${this.apiUrl}/logs.json`).pipe(
      map(this.getLogsArray),
      delay(1500)
    );
  }

  private getLogsArray(logsObj: object) {
    const logs: Logs [] = [];

    if (logsObj === null) {
      return [];
    }

    Object.keys(logsObj).forEach(key => {
      const log: Logs = logsObj[key];
      log.idtoken = key;

      logs.push(log);
    });

    return logs;
  }
}
