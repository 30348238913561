import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Logs } from '../../../models/logs.models';

@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.css"]
})
export class LogsComponent implements OnInit {
  logs: Array<Logs> = new Array<Logs>();
  loading = true;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.GetLogs();
  }

  GetLogs() {
    this.authService.getLogs().subscribe((response: Array<Logs>) => {
      this.logs = response;
      this.loading = false;
    });
  }
}


