import { Routes } from '@angular/router';
import { CallbackComponent } from './components/callback/callback.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// import { AuthGuard } from './guard/auth.guard';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }

];
